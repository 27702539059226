.splash-page {
  background: "white" no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  color: rgb(9, 209, 29);
  font-size: 5rem;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 0;
  /* text-shadow: 2px 2px 4px #000000; */
}
.form-container {
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out;
}

.form-container input {
  margin: 10px;
  width: 300px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: #f2f2f2;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.form-container input:focus {
  outline: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.submit-button {
  margin: 10px;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: rgb(9, 209, 29);
  color: white;
  font-weight: bold;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  text-transform: capitalize;
  font-family: "Bebas Neue", bold, sans-serif;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  transition: box-shadow 0.3s ease-in-out;
}

.modal-content button {
  margin: 10px;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: none;
  font-family: "Bebas Neue", sans-serif;
  background-color: rgb(9, 209, 29);
  color: white;
  font-weight: bold;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
